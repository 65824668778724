html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: #2a2b0e;
  background-image: linear-gradient(85deg, #111 0%, #442861 100%);;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui {
  &.container.content {
      margin: 2em auto;
      padding: 2em;
      background-color: rgb(227, 227, 227);
      box-shadow: 0px 0px 5px -1px #929292, inset 0px 0px 5px 0px #696969;
    }

  .form {
    margin: 2em auto;
    @media only screen and (max-width: 767px) {
      & .fields>.field {
        margin-top: 1em;
      }
    }

    @media only screen and (min-width: 767px) {
      .field{
        position: relative;
      }
  
      .ui.pointing.prompt{
        position: absolute;
        top: 90%;
        left: 15%;
        z-index: 9999;
      }
    }

    .confirm-panel{
      text-align: right;
      p.price{
        display: inline-block;
        margin: .25em 1em;
        font-size: 1.4em;
        font-weight: bold;
      }

      button{
        display: inline-block;
      }
    }
  }

  &.container{
    overflow: hidden;
  }

  &.container.text{
    position: relative;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    left: 0;

    &.left{
      opacity: 0;
      left: -90%;
    }

    &.right{
      opacity: 0;
      left: 90%;
    }
  }
}